<template>
    <div class="pa-0 video-axteroid">
      <v-img v-if="env === 'ax'" class="rounded-0"  position="right" :src="require(`@/assets/ax/home--${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)" style="position: absolute;  min-height: 100%;"/>
      <!-- <video class="video-axteroid" v-if="env === 'ax'" autoplay muted loop>
        <source :src="require(`@/assets/home--${$vuetify.theme.dark ? 'dark' : 'light'}.mp4`)" type="video/mp4">
      </video> -->
      <div v-else>
        <ul>
          <li v-for="item in 3" :key="item">
            <v-img
              class="mt-n1"
              position="right"
              :src="require(`@/assets/fc/img-FC.png`)"
            />
          </li>
        </ul>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      message: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      env: process.env?.VUE_APP_MODE ?? 'ax'
    })
  }
  </script>
  <style lang="scss" scoped>
  .video-axteroid {
    position: fixed;
    bottom: 0;
    left: 0;
    // right: 0;
    width: 61%;
    min-height: 100%;
    z-index: 0;
  }

  @keyframes moveSlideshow {
    0% {
      margin-top: 0px;
   }
   100% {
      margin-top: -2125px;
   }
  }

  ul {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    list-style: none;
    -webkit-animation: moveSlideshow 70s linear infinite;
    animation: moveSlideshow 70s linear infinite;
    position: fixed;
    top: 0;
    background-color: var(--v-lightGrey-base) !important;
  }

  div, li {
    width: 70%;
    min-height: 100vh;
  }

  div {
    position: relative;
    overflow: hidden;
  }
  </style>